import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Box } from '@mui/material';

import { useUser } from '../../auth/useUser';
import { useGlobalContext, usePaginationContext } from '../../auth/useGlobalContext';

import SiraNoRowsOverlay from '../SiraNoRowsOverlay';
import { Account } from '../../api/AccountApi.d';
import { AccountOwner } from '../../api/AccountOwnerApi.d';
import { format, parseISO } from 'date-fns';

import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { getAccountOwner } from '../../api/AccountOwnerApi';
import { Allocation, InvestmentRate  } from '../../api/InvestmentRateApi.d';
import { globalPaginationOptions } from '../../app.constants';
import { currencyValueFormatterPro } from '../../utils/DataGrid.utils';

interface AccountOwnerAccountInvestmentsProps {
  account: Account;
  accountOwner: AccountOwner;
}


export const POSITIVE_DOLLAR_SCHEMA = {
  taxYearFilters: yup.array(yup.number()).label('Fair Market Value'),
};

function AccountOwnerAccountInvestments(props: AccountOwnerAccountInvestmentsProps) {
  let isMounted = true;
  const { accountOwner } = props;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const { accountOwnerId } = accountOwner;
  const { user } = useUser();
  const { addGlobalMessage, organization } = useGlobalContext();
  const { setGlobalPageSize, globalPageSize } = usePaginationContext();

  const [allocations, setAllocations] = useState([] as Array<Allocation>);
  const [investmentRates, setInvestmentRates] = useState(
    [] as Array<InvestmentRate>,
  );
    // Get the accountOwner for the account loaded
    const updateAccountOwner = async (): Promise<AccountOwner> => {
        setIsLoading(true);
        let accountOwner = {} as AccountOwner;
    
        await getAccountOwner(accountOwnerId, user.organizationId, user.token, user)
          .then((res) => {
            if (isMounted) {
              accountOwner =  res.data;
            }
          })
          .catch(() => {
                      if (isMounted) {
              setIsLoading(false);
            }
          });
  
          return accountOwner;
      };
  
    // Maps incoming allocations to their respective investment rate
    function mapSymitarInitialAllocations(
      rates: Array<any>,
    ): Array<any> {
      return rates.map((rate, idx) => {  
        return {
            id: `${idx}-${rate.id}`,
          description: rate.description,
          availableBalance: rate.availableBalance,
          balance: rate.balance,
          rate: rate.divRate,
            openDate: rate.openDate,
        } as any;
      });
    }

  async function getInitialInvestmentRates(): Promise<void> {
    if (user.token && user.organizationId) {
      setIsLoading(true);

      if (organization.coreIntegration === 'SYMITAR') {
        const symitarDTO = (await updateAccountOwner()).symitarAccountDTO;
        const initialAllocations = mapSymitarInitialAllocations(
          symitarDTO.investments,
        );

        if (isMounted) {
          setInvestmentRates(initialAllocations);
          setIsLoading(false);
        }
      }
    }
  }

  useEffect(() => {
    getInitialInvestmentRates();

    return () => {
      isMounted = false;
    };
  }, [user.token, user.organizationId]);

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'availableBalance',
      headerName: 'Available Balance',
      flex: 1,
      valueFormatter: currencyValueFormatterPro,
    },
    {
      field: 'balance',
      headerName: 'Balance',
      flex: 1,
      valueFormatter: currencyValueFormatterPro,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      flex: 1,
    },
    {
      field: 'openDate',
      headerName: 'Open Date',
      flex: 1,
      valueGetter: (value) => {
        const time = value as string;
        if (time && !Number.isNaN(Date.parse(time))) {
          return format(parseISO(time), 'MM/dd/yyyy');
        }
        return '';
      },    },
  ];

  return (
    <Box width="1">
      <Box>
        <DataGridPro
          sx={{
            filter: isLoading ? 'blur(5px)' : '',
            transition: 'filter 0.33s ease-in-out',
          }}
          slots={{
            noRowsOverlay: SiraNoRowsOverlay,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: globalPageSize } },
          }}
          pagination
          pageSizeOptions={globalPaginationOptions}
          onPaginationModelChange={setGlobalPageSize}
          autoHeight
          columns={columns}
          rows={investmentRates}
        />
      </Box>
    </Box>
  );
}

export default AccountOwnerAccountInvestments;
