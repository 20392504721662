export interface InvestmentRate {
  id?: string;
  investmentRateId?: string;
  financialOrganizationId?: 0;
  description?: string;
  term?: string;
  interestRate?: number;
  apy?: number;
  minimumInvestment?: number;
  active?: boolean;
  enrollmentFee?: string;
  annualFee?: string;
  earlyWithdrawalFee?: string;
  earlyWithdrawalOption?: EarlyWithdrawalOption;
  additionalFeeDescription?: string;
  additionalFeeAmount?: string;
  compoundingFrequency?: CompundingFrequency;
  defaultInvestment?: boolean;
  truthInSavingsDocument?: string;
  importFiles?: File;
  divRate?: number;
  minimumDeposit?: number;
  balance?: number;
  type?: string;
}

export interface AllocationsResponse {
  data?: Array<Allocation>;
  errorMessage?: string;
}

export interface InvestmentRateResponse {
  data: Array<InvestmentRate>;
  errorMessage: any;
}

export interface TISDocumentMappingResponse {
  data: Array<TISDocumentMapping>;
  errorMessage: any;
}


export interface TISDocumentMapping{
  item: string;
  description: string;
}

export interface AddChangeInvestmentRateResponse {
  data: InvestmentRate;
  errorMessage: any;
}

export interface Allocation extends InvestmentRate {
  amount: string;
  contributionId: string;
  investmentAllowcationId?: string;
  investmentNumber?: string;
  investmentRateId: string | null;
  coreInvestmentId?: string;
  type?: string;
}

export enum EarlyWithdrawalOption {
  daysInterest = 'DAYS_OF_INTEREST',
  monthsInterest = 'MONTHS_OF_INTEREST',
  dollarAmount = 'DOLLAR_AMOUNT',
}

export enum CompoundingFrequency {
  yearly = 'YEARLY',
  halfYearly = 'HALF_YEARLY',
  quarterly = 'QUARTERLY',
  monthly = 'MONTHLY',
  weekly = 'WEEKLY',
  daily = 'DAILY',
}
